<template>
  <!-- 车辆管理页面 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
      <el-col :span="3">
        <span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-button size="small" @click="openPur">删除</el-button>
            <el-button size="small" @click="addPurs">新增</el-button>
            <el-button size="small" @click="openPolice" type="danger">开启报警</el-button>
            <el-button size="small" @click="closePolice">关闭报警</el-button>
            <el-input
            @keyup.enter.native="search"
            size="small"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="purSearch">
            </el-input>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav"
    v-loading="loading"
    element-loading-text = "加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%;"
          @selection-change="purChange">
            <el-table-column type="selection"></el-table-column>
            <el-table-column prop="id" v-if="show">
            </el-table-column>
            <el-table-column label="车牌号" :show-overflow-tooltip="true" prop="licensePlate">
            </el-table-column>
            <el-table-column label="司机姓名" :show-overflow-tooltip="true" prop="driverName">
            </el-table-column>
            <el-table-column label="联系方式" :show-overflow-tooltip="true" prop="phone">
            </el-table-column>
            <el-table-column label="报警状态" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.alarmFlag">开启</span>
                <span v-else>关闭</span>
              </template>
            </el-table-column>
            <el-table-column label="绑定线路名称" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <div v-if="scope.row.routeCount > 0">
                  <span style="color:rgb(58, 111, 195);" @click="details(scope.row.id)">详情</span>
                </div>
                <span v-else>---</span>
              </template>
            </el-table-column>
            <el-table-column label="终端SN码" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.terminalId">{{scope.row.terminalId}}</span>
                <span v-else style="color:rgba(235, 87, 87, 1);">未绑定</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作" width="180" fixed="right">
              <template slot-scope="scope">
                <el-button @click="EditPurs(scope.row)" size="mini">编辑</el-button>
                <el-button @click="Unbind(scope.row)" size="mini" v-if="scope.row.terminalId">取消绑定</el-button>
                <el-button @click="bind(scope.row)" size="mini" v-else>绑定设备</el-button>
              </template>
            </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable">
              <div class="dataAva">
                <img src="../assets/zanwushuju.png" alt="">
                <p>暂无数据，您可以新建试试</p>
                <el-button size="small" style="margin-left: 24px" @click="addPurs">新增</el-button>
              </div>
          </div>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
        background
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size.sync="size"
        :pager-count="5"
        :current-page.sync="current"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change='getpurcon'
        @size-change='getpurcon'>
        </el-pagination>
      </div>
      <!-- 车辆绑定弹窗 -->
      <el-dialog
        title="绑定设备"
        :closeOnClickModal="false"
        :visible.sync="bindShow"
        width="35%"
        :before-close="bindClose">
          <el-form style="width:95%;" :model="bindData" :rules="bindrules" ref="bindData" size="small" label-width="100px">
            <el-form-item label="设备绑定">
              <el-select v-model="bindData.terminalId" clearable filterable placeholder="请选择终端SN码" style="width:100%;">
                  <el-option
                  v-for="item in bindoptions"
                  :key="item.terminalId"
                  :label="'终端SN码：' + item.terminalId + ' 车辆备注：' + (item.licensePlateRemark?item.licensePlateRemark:'--')"
                  :value="item.terminalId">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="bindsubmitm('bindData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="bindresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- 新建车辆信息弹窗 -->
      <el-dialog
        :closeOnClickModal="false"
        title="新建车辆信息"
        :visible.sync="paymentShow"
        width="35%"
        :before-close="paymentShowClose">
          <el-form style="width:95%;" :model="AddData" :rules="paymentrules" ref="AddData" size="small" label-width="100px">
            <el-form-item label="车牌号" prop="licensePlate">
              <el-input v-model="AddData.licensePlate"></el-input>
            </el-form-item>
            <el-form-item label="司机姓名" prop="driverName">
              <el-input v-model="AddData.driverName"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="AddData.phone"></el-input>
            </el-form-item>
            <el-form-item label="路线名称">
              <el-select v-model="AddData.ids" clearable filterable multiple placeholder="请选择路线" style="width:100%;">
                  <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.routeName"
                  :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报警开关" prop="alarmFlag">
               <el-switch v-model="AddData.alarmFlag"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="paymentsubmitm('AddData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="paymentresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- 编辑车辆信息弹窗 -->
      <el-dialog
        title="编辑车辆信息"
        :closeOnClickModal="false"
        :visible.sync="editpaymentShow"
        width="35%"
        :before-close="editpaymentShowClose">
          <el-form style="width:95%;" :model="editData" :rules="editpaymentrules" ref="editData" size="small" label-width="100px">
            <el-form-item label="车牌号" prop="licensePlate">
              <el-input v-model="editData.licensePlate" disabled></el-input>
            </el-form-item>
            <el-form-item label="司机姓名" prop="driverName">
              <el-input v-model="editData.driverName"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="editData.phone"></el-input>
            </el-form-item>
            <el-form-item label="路线名称">
              <el-select v-model="editData.ids" clearable multiple filterable placeholder="请选择路线" style="width:100%;">
                  <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.routeName"
                  :value="item.id">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="报警开关" prop="alarmFlag">
               <el-switch v-model="editData.alarmFlag"></el-switch>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="editpaymentsubmitm('editData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="editpaymentresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
      <!-- 抽屉 查看车辆详情功能 -->
      <el-drawer title="车辆详情列表" :visible.sync="drawer" size="500px" @close="closedrawerno">
        <div class="detilsBox" v-if="delistData.length != 0">
          <div  class="detilconent" v-for="(v,k) in delistData" :key="k">
            <span style="width: 25%;">线路名称{{k+1}}：{{v.routeName}}</span>
          </div>
        </div>
        <div class="detilsBox" v-else style="margin-top:40%;">
          <el-empty description="暂无车辆信息"></el-empty>
        </div>
      </el-drawer>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
<script>
export default {
    data() {
        return {
          // 车辆详情
          drawer:false,
          delistData:[],

          loading:false,
          current:1,              // 当前页数
          size:30,                // 显示条数
          total:0,                // 总条数
          delmul:[],              // 批量删除
          show:false,
          purTitle: "",           // 标题
          purSearch: "",          // 搜索        
          multipleSelection: [],  // 选中数据
          tableData: [],          // 表格数据  
          // 车辆绑定
          bindShow:false,
          bindData:{},
          bindrules: {},
          bindoptions: [],
          // 新建车辆信息弹窗
          paymentShow:false,
          AddData:{
            alarmFlag: true,
          },
          paymentrules: {
              licensePlate: [
                { required: true, message: '请输入车牌号', trigger: 'blur' },
                { pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/, message: '请输入正确的车牌号', trigger: 'blur'}
              ],
              driverName: [
                { required: true, message: '请输入司机姓名', trigger: 'blur' },
                { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '姓名必须2-4位中文', trigger: 'blur'}
              ],
              phone: [
                { required: true, message: '请输入司机联系方式', trigger: 'blur' },
                { pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/, message: '请输入正确的号码格式', trigger: 'blur'}
              ],
          },
          options: [],
          // 车辆编辑
          editpaymentShow:false,
          editData:{
            alarmFlag: true,
          },
          editpaymentrules: {
              licensePlate: [
                { required: true, message: '请输入车牌号', trigger: 'blur' },
                { pattern: /(^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$)/, message: '请输入正确的车牌号', trigger: 'blur'}
              ],
              driverName: [
                { required: true, message: '请输入司机姓名', trigger: 'blur' },
                { pattern: /^[\u4E00-\u9FA5]{2,4}$/, message: '姓名必须2-4位中文', trigger: 'blur'}
              ],
              phone: [
                { required: true, message: '请输入司机联系方式', trigger: 'blur' },
                { pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/, message: '请输入正确的号码格式', trigger: 'blur'}
              ],
          },
        };
    },
    created() {},
    mounted(){
      this.purTitle = this.$route.meta.title;
      this.getpurcon();
    },
    methods: {
      // 车辆详情
      details(id){
        this.drawer = true;
        this.api.riskCar.one({id:id})
        .then(res=>{
          console.log(res)
          if(res.data.code == 200){
            this.delistData = res.data.data.fences;
          }
        })
      },
      // 关闭抽屉回调
      closedrawerno(){
        this.drawer = false;
      },

      // 表格数据 
      getpurcon(){
        this.loading = true;
        // 传参
        var listByPageData = {
          searchStr:this.purSearch,
          page:this.current,
          size:this.size
        }
        // 渲染表格
        this.api.riskCar.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
              this.tableData = res.data.data.dataList;
              this.total = res.data.data.totalCount;
          }
        })
      },
      // 搜索功能
      search() {
        this.tableData = [];
        this.getpurcon();
        this.current = 1;
      },
      // 表格选中数据
      purChange(val) {
        this.multipleSelection = val;
        this.delmul = [];
        for(let i=0;i<this.multipleSelection.length;i++){
          this.delmul.push(this.multipleSelection[i].id) 
        }
      },
      // 绑定线路列表
      road(){
        this.api.riskElect.list()
        .then(res=>{
          if(res.data.code == 200){
            this.options = res.data.data;
          }
        })
      },
      // 新建车辆信息
      addPurs(){
        this.paymentShow = true;
        this.road();
      },
      // 新建车辆信息取消
      paymentShowClose(){
        this.paymentShow = false;
        this.AddData = {
          alarmFlag: true,
        }
      },
      // 取消新建车辆信息提交
      paymentresetForm() {
        this.paymentShow = false;
        this.AddData = {
          alarmFlag: true,
        }
      },
      // 新建车辆信息提交
      paymentsubmitm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.api.riskCar.add(this.AddData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '保存成功!'
                });
                this.AddData = {
                  alarmFlag: true,
                }
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.paymentShow = false;
              }else if(res.data.code == 602){
                this.$message({
                  type: 'warning',
                  message: res.data.msg
                });
              }
            })
          }
        });
      },
      // 车辆解绑
      Unbind(data){
          this.$confirm('此操作将解除绑定设备, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let newdata = {
              terminalId:data.terminalId,
              carId:data.id,
              licensePlate:data.licensePlate
            }
            this.api.riskCar.updBthById(newdata)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '解绑设备成功!'
                });
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消解绑设备操作'
            });          
          });
      },
      // 车辆绑定
      bind(data){
        this.bindShow = true;
        this.bindData.carId = data.id;
        this.bindData.licensePlate = data.licensePlate;
        this.api.Terminal.list({bindCar:false})
        .then(res=>{
          if(res.data.code == 200){
            this.bindoptions = res.data.data;
          }
        })
      },
      // 绑定设备弹窗取消
      bindClose(){
        this.bindShow = false;
        this.bindData = {}
      },
      // 取消绑定设备信息提交
      bindresetForm() {
        this.bindShow = false;
        this.bindData = {}
      },
      // 提交绑定数据
      bindsubmitm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.api.riskCar.bindCar(this.bindData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '绑定设备成功!'
                });
                this.bindData = {};
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.bindShow = false;
              }
            })
          }
        });
      },
      // 编辑车辆信息
      EditPurs(data){
        this.editpaymentShow = true;
        this.road();
        this.editData = JSON.parse(JSON.stringify(data));
        // console.log(data)
        this.api.riskCar.one({id:this.editData.id})
        .then(res=>{
          if(res.data.code == 200){
            let ids = [];
            for(let key in res.data.data.carfences){
              console.log(res.data.data)
              ids.push(res.data.data.carfences[key].fenceId)
            }
            console.log(ids)
            this.editData.ids = ids;
          }
        })
      },
      // 编辑车辆信息取消
      editpaymentShowClose(){
        this.editpaymentShow = false;
        this.editData = {
          alarmFlag: true,
        }
      },
      // 取消新建车辆信息提交
      editpaymentresetForm() {
        this.editpaymentShow = false;
        this.editData = {
          alarmFlag: true,
        }
      },
      // 编辑车辆信息提交
      editpaymentsubmitm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.api.riskCar.edit(this.editData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '编辑成功!'
                });
                this.editData = {
                  alarmFlag: true,
                }
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.editpaymentShow = false;
              }
            })
          }else if(res.data.code == 602){
            this.$message({
              type: 'warning',
              message: res.data.msg
            });
          }
        });
      },
      // 批量开启报警
      openPolice(){
          if(this.multipleSelection == ""){
            this.$message({
                type: 'warning',
                message: '请选择要开启报警的车辆!'
            });
          }else{
            let arr = [];
            for(let i=0;i<this.multipleSelection.length;i++){
              arr.push({
                id:this.multipleSelection[i].id,
                alarmFlag:true
              }) 
            }
            this.api.riskCar.closeAlarmBatch(arr)
            .then(res=>{
              if(res.data.code == 200){
                  this.$message({
                    type: 'success',
                    message: '批量开启报警成功!'
                  });
                  this.tableData = [];
                  this.getpurcon();
                  this.current = 1;
              }
            })
          }
      },
      // 批量关闭报警
      closePolice(){
          if(this.multipleSelection == ""){
            this.$message({
                type: 'warning',
                message: '请选择要关闭报警的车辆!'
            });
          }else{
            let arr = [];
            for(let i=0;i<this.multipleSelection.length;i++){
              arr.push({
                id:this.multipleSelection[i].id,
                alarmFlag:false
              }) 
            }
            this.api.riskCar.closeAlarmBatch(arr)
            .then(res=>{
              if(res.data.code == 200){
                  this.$message({
                    type: 'success',
                    message: '批量关闭报警成功!'
                  });
                  this.tableData = [];
                  this.getpurcon();
                  this.current = 1;
              }
            })
          }
      },
      // 删除功能
      openPur() {
          if(this.multipleSelection == ""){
            this.$message({
                type: 'warning',
                message: '请选择要删除的数据!'
            });
          }else{
            this.$confirm('此操作将永久删除该车辆信息, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
              this.api.riskCar.del(this.delmul)
              .then(res=>{
                if(res.data.code == 200){
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.tableData = [];
                  this.getpurcon();
                  this.current = 1;
                  if(this.tableData == []){
                      this.dataAva = true;
                  }
                }
              })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
          }
      },
    },
};
</script>
<style lang="less" scoped>
// 详情样式开始
.detilsBox{
  width: 100%;
  height: 100%;
  padding: 25px;
  box-sizing: border-box;
  h5{
    font-size: 16px;
    line-height: 58px;
    color: #333333;
  }
  .detilconent{
    width: 100%;
    span{
      display: inline-block;
      width: 33.33%;
      font-size: 14px;
      line-height: 24px;
      color: #333333;
    }
  }
}
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table{
    width:100%;
    height: 94%;
  } 
  // 暂无数据样式开始
  .dataAvailable{
    width: 100%;
    height: 245px;
    position: relative;
    margin-top:185px;
    margin-bottom:279px;
    .dataAva{
      position: absolute;
      top:75%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img{
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button{
        margin-bottom:100px;
      }
    }
  }
  // 暂无数据样式结束
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing:border-box;
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
</style>